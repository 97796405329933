import { SpringValue, useSpring } from 'react-spring';
import { RefObject, useEffect } from 'react';
import * as d3 from 'd3-ease';

const useParallax = (ref: RefObject<any>): { offset: SpringValue<number> } => {
  const [{ offset }, set] = useSpring(() => ({
    offset: 0,
    config: {
      easing: (t) => d3.easeQuadOut(t),
    },
  }));

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top || 0;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { offset };
};
export default useParallax;
