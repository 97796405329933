import { useState } from 'react';
import formSender from 'utils/formSender';
import { TApiPaths } from 'data/api-routes';
import { IFormSenderHook, TOnSubmit, TStatus } from 'types/hooks';

const useFormSender = (route: TApiPaths): IFormSenderHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const status: TStatus = { loading, error, success };

  const onSubmit: TOnSubmit = async (values, actions, cb, externalParameters = {}): Promise<void> => {
    const resetEachField = () => Object.keys(values).map((key) => actions.resetFieldState(key));
    if (!success) {
      try {
        setLoading(true);
        await formSender({ ...values, ...externalParameters }, route);
        setSuccess(true);
        actions.reset();
        resetEachField();
        setTimeout(() => {
          setSuccess(false);
        }, 7000);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return { status, onSubmit };
};

export default useFormSender;
