import { IFormSenderParams } from 'types/email';
import axios from 'axios';
import { TApiPaths } from 'data/api-routes';

const formSender = async (formValues: IFormSenderParams, path: TApiPaths): Promise<void> => {
  const formData = new FormData();
  Object.entries(formValues)
    .filter(([, value]) => !!value)
    .forEach(([key, value]) => formData.append(key, value));

  try {
    const params = { headers: { 'Content-Type': 'multipart/form-data' } };
    return await axios.post(path, formData, params);
  } catch (err) {
    return err;
  }
};

export default formSender;
